<section class="error-page">
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="container">
      <img src="../../assets/ic-broken-heart.svg" alt="broken-heart">
      <div>Whoops!</div>
      <h3>404 - Page Not Found</h3>
      <p>The page your are looking for was moved, removed, renamed or might never existed.</p>
      <button class="btn btn-lg btn-outline-light" routerLink="/">Go Home</button>
    </div>
  </div>
</section>
