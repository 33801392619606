import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      const element = document.getElementById('scrollspy-navbar');
      element.classList.add('bg-danger');
    } else {
      const element = document.getElementById('scrollspy-navbar');
      element.classList.remove('bg-danger');
    }
  }

}
