<footer>
  <div class="p-4">
    <div class="row">
      <div class="col-md-3">
        <ul>
          <li><strong>SITE</strong></li>
          <li><a href="#" >Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <ul>
          <li><strong>CONTACT</strong></li>
          <li><a href="tel:+27215550270">+27 21 555 0270</a></li>
          <li><a href="mailto:irene@icarens.co.za" target="_blank">irene@icarens.co.za</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <ul>
          <li><strong>SOCIAL MEDIA</strong></li>
          <li><a href="https://www.facebook.com/icarens" target="_blank"><img src="../../assets/ic-facebook.png" alt="Facebook" width="25px">&nbsp; @icarens</a></li>
<!--          <li>Instagram</li>-->
        </ul>
      </div>
      <div class="col-md-3">
        <div class="d-flex justify-content-center align-items-center h-100">
          <img src="../../assets/iCare_logo_white.svg" alt="iCare Nursing Services">
        </div>
      </div>
    </div>
  </div>
  <div class="copy-right">
    &copy; <span>{{currentYear}}</span> Copyright iCare Nursing Services
    <div class="small">Designed &amp; Developed by <a href="http://www.nicoleabbott.co.za" target="_blank">Nicole Abbott</a></div>
  </div>
</footer>
