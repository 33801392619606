<section id="contact" class="contact-section">
  <div class="container">
    <h1 data-aos="flip-up" data-aos-duration="1000" data-aos-delay="100">Get In Touch</h1>
    <p data-aos="flip-up" data-aos-duration="1000" data-aos-delay="400">If you have any questions or want to find out more about us, please do not hesitate to contact us.</p>

    <div class="contact-section__buttons">
      <a data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800" href="tel:+27215550270" class="btn btn-lg btn-outline-light">+27 21 555 0270</a>
      <a data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800" href="mailto:irene@icarens.co.za" target="_blank" class="btn btn-lg btn-outline-light">irene@icarens.co.za</a>
    </div>
    <div class="social-media">
      <a href="https://www.facebook.com/icarens" target="_blank"><img src="../../assets/ic-facebook.png" alt="Facebook" width="40px"></a>
    </div>
  </div>
</section>
