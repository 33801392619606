<div class="privacy-policy">
  <div class="container">
    <h1>iCare Nursing Services Privacy Policy:</h1>

    <p>We wish to inform you about the processing of your Personal Information by <strong>iCare Nursing Services</strong> under registration of practice number 0952281, having its principal place of business at 47 Ocean Spirit Avenue, Milnerton, 7441 and your rights under the Protection of Personal Information Act.</p>
    <p>This Privacy Policy governs the manner in which <strong>iCare Nursing Services</strong>  (‘we’, ‘our’, ‘us’) treats your personal information collected electronically when you use our website (www.icarens.co.za) to apply online for services, or after an assessment is done at your venue of choice.</p>
    <p>We respect your privacy and your personal information, and for this reason, we will take all reasonable measures, in accordance with this Policy, to protect your personal information to keep it confidential, even when you are no longer our client.</p>
    <p>We will maintain the confidentiality of your personal information and comply with the Protection of Personal Information Act 4 of 2013 (POPIA) when processing your personal information.  This Policy is in line with the above mentioned Act.</p>

    <div><strong>We briefly outline these principles below:</strong><br>
      <ul>
        <li>We will only collect, disclose, collate, process and store ('use') your personal information with your express written permission, unless legally required to do so, and will only use such information for the lawful purpose for which it is required.</li>
        <li>We will disclose in writing, the specific purpose for which we use, request and store your personal information.  We will also keep a record of that personal information and the specific purpose for which we collect it.</li>
      </ul>
    </div>

    <div>
      <ol>
        <li>
          <strong>INFORMATION DISCLOSURE</strong>
          <ol>
            <li><strong>What is personal information?</strong><br>
              Personal information refers to any information that identifies you or specifically relates to you.  Personal information includes, but is not limited to, the following information about you:
              <ul>
                <li>Your marital status (like married, single, divorced); your national origin; your age; your language; birth; education.</li>
                <li>Your financial information, which may include your financial history and information (like your income or your buying, investing and banking behaviour based on, amongst others, account transactions).</li>
                <li>Your identifying number (like an account number, identity number or passport number).</li>
                <li>Your e-mail address; location information; physical address (like residential address, work address or your physical location); and telephone number (including your cellular number, home landline or office work number).</li>
                <li>Your online identifiers such as social media profiles.</li>
                <li>Your biometric information (like fingerprints, face recognition, signature or voice)</li>
                <li>Your race and/or gender.</li>
                <li>Your physical health; mental health; well-being; disability; religion; belief; conscience; culture.</li>
                <li>Your medical history (like your HIV / AIDS status and any medical history disclosed or obtained); criminal history; employment history.</li>
                <li>Your personal views, preferences and opinions.</li>
                <li>Your confidential correspondence; and / or</li>
                <li>Another’s views or opinions about you and your name also constitute your personal information.</li>
              </ul>
            </li>
            <li><strong>What is special personal Information</strong><br>
              Special personal information is personal information about the following:
              <ul>
                <li>Your religious and your philosophical beliefs (for example where you enter a competition, and you are requested to express your philosophical view).</li>
                <li>Your race (like where you apply for a product or service where the statistical information must be recorded).</li>
                <li>Your ethnic origin.</li>
                <li>Your trade union membership.</li>
                <li>Your political beliefs.</li>
                <li>Your health (like where you apply for an insurance policy or medical/health related products);</li>
                <li>Your biometric information (like to verify your identity); and / or</li>
                <li>Your criminal behaviour and alleged commission of an offence (like to prevent money laundering as required by law or when you apply for employment or enter into a relationship with us).</li>
              </ul>
            </li>
            <li><strong>How does iCare Nursing Services collect personal information?</strong><br>
              <strong>iCare Nursing Services</strong> collects information either directly from you, your family or the medical professional requesting our services, and may appoint third parties to collect information on its behalf.  The source from which personal information was obtained, if not directly from the data subject, will be disclosed.
            </li>
            <li><strong>What type of personal information does iCare Nursing Services collect?</strong><br>
              Personal information collected by <strong>iCare Nursing Services</strong> can include a data subject’s name, contact, birth date, identity number, gender, employment details, marital, family, policy, location information, online identifier, bank account, medical or health information.
              When personal information is collected, <strong>iCare Nursing Services</strong> will indicate the purpose for the collection and whether the information required is compulsory or voluntary.
            </li>
            <li><strong>When will we process your personal information?</strong><br>
              We will only process your personal information for lawful purposes relating to our business if the following circumstances apply:
              <ul>
                <li>You have consented thereto.</li>
                <li>A person legally authorised by you, the law or a court, has consented thereto.</li>
                <li>It is necessary to conclude or perform under a contract we have with you.</li>
                <li>The law requires or permits it.</li>
                <li>It is required to protect or pursue your, our or a third party’s legitimate interest; and/or</li>
                <li>You are a child, and a competent person (such as a parent or guardian) has consented thereto on your behalf.</li>
              </ul>
            </li>
            <li><strong>When will we process your special personal information?</strong><br>
              We may process your special personal information in the following circumstances:
              <ul>
                <li>If you have consented to the processing thereto.</li>
                <li>If the processing is needed to create, use or protect a right or obligation in law.</li>
                <li>If the processing is for statistical or research purposes.</li>
                <li>If the special personal information was made public by you.</li>
                <li>If the processing is required by law.</li>
                <li>If racial information is processed and the processing is required to identify you; and/or</li>
                <li>If health information is processed, and the processing is to determine your insurance risk, or to comply with an insurance policy, or to enforce an insurance right or obligation.</li>
              </ul>
            </li>
            <li><strong>Reasons for processing your personal information</strong><br>
              We will process your personal information for the following reasons:
              <ul>
                <li>Payment processing service providers, merchants, banks and other persons that assists with the processing of your payment instructions.</li>
                <li>Insurers, brokers, other financial institutions or other organisations that assist with insurance and assurance underwriting, the providing of insurance and assurance policies and products, the assessment of insurance and assurance claims, and other related purposes.</li>
                <li>Law enforcement and fraud prevention agencies, and other persons tasked with the prevention and prosecution of crime.</li>
                <li>Regulatory authorities, industry ombudsmen, government departments, and local and international tax authorities.</li>
                <li>Credit bureaux.</li>
                <li>Trustees, executors or curators appointed by a court of law.</li>
                <li>Cheque verification service providers.</li>
                <li>Our service providers, agents and sub-contractors such as couriers and other persons we use to offer and provide products and services to you.</li>
                <li>Courts of law or tribunals.</li>
                <li>To process payment instruments and payment instructions (such as a debit order or a stop order deduction).</li>
                <li>To create, manufacture and print payment instruments (such as statements) and payment devices (such as a debit card);</li>
                <li>To develop credit models and credit tools.</li>
                <li>To open, manage and maintain your accounts/policies, contracts, agreements or relationship with us.</li>
                <li>To disclose and obtain personal information from credit bureaux regarding your credit history.</li>
                <li>To enable us to deliver goods, documents or notices to you.</li>
                <li>For security and identity verification, and to check the accuracy of your personal information.</li>
                <li>To communicate with you and carry out your instructions and requests.</li>
                <li>For customer satisfaction surveys, promotional and other competitions.</li>
                <li>For insurance and assurance underwriting and administration.</li>
                <li>To process or consider or assess insurance or assurance claims.</li>
                <li>To provide insurance and assurance policies and products, and related services.</li>
                <li>To enable you to participate in and make use of value-added products and services.</li>
                <li>For any other related purposes.</li>
              </ul>
            </li>
            <li><strong>Processing Information of a child</strong><br>
              <p>Protecting privacy of children – <strong>iCare Nursing Services</strong> takes the privacy of children very seriously.  Children under the age of 18 years should obtain their guardian's consent before providing/submitting any personal information about themselves.</p>
              <p>We will not require children under this age to provide any personal information other than that which is reasonably necessary.</p>
              <p>If we determine that a user is under the age of 18, we will not use or maintain his/her personal information without the guardian's consent.</p>
              <p>A child is a person who is defined as a child by a country’s legislation, and who has not been recognised as an adult by the courts.  We process the personal information of children if the law permits this.  We will only process the personal information of children if any one or more of following applies:</p>
              <ul>
                <li>A person with the ability to sign legal agreements has consented to the processing, being the parent or guardian of the child.</li>
                <li>The processing is needed to create, use or protect a right or obligation in law, such as where the child is an heir in a will, a beneficiary of a trust, a beneficiary of an insurance policy or an insured person in terms of an insurance policy.</li>
                <li>The child’s personal information was made public by the child, with the consent of a person who can sign legal agreements.</li>
                <li>The processing is for statistical or research purposes and all legal conditions are met.</li>
                <li>Where the child is an heir in a will, if required to give effect to the will.</li>
                <li>Where the child is a beneficiary of a trust, if required to give effect to the trust deed.</li>
                <li>Where the child is legally old enough to open a bank account without assistance from their parent or guardian.</li>
                <li>Where the child is legally old enough to sign a document as a witness without assistance from their parent or guardian.</li>
                <li>Where the child benefits from a bank account such as an investment or savings account; and/or</li>
                <li>Where the child is an insured person or beneficiary of an insurance policy, if required to give effect to the policy.</li>
              </ul>
            </li>
            <li><strong>Obtaining personal information about clients</strong><br>
              <p>We collect information about you:</p>
              <ul>
                <li>Directly from you.</li>
                <li>Based on your use of our products, services, or service channels (such as our websites or applications).</li>
                <li>Based on how you engage or interact with us, such as on social media, and through e-mails, letters, telephone calls, and surveys.</li>
                <li>From public sources (such as newspapers); and</li>
                <li>From third parties for the purposes of conducting our business.</li>
              </ul><br>
              <p>If the law requires us to do so, we will ask for your consent before collecting personal information about you from third parties.  The third parties from whom we may collect your personal information include, but are not limited to, the following:</p>
              <ul>
                <li>Any connected companies, subsidiary companies, its associates, cessionaries, delegates, assignees, affiliates or successors in title and/or appointed third parties (such as its authorised agents, partners, contractors and suppliers) for any of the purposes identified in this Privacy Policy.</li>
                <li>Your spouse, dependants, partners, employer, joint applicant or account holder and other similar sources.</li>
                <li>Attorneys, tracing agents and other persons that assist with the enforcement of agreements.</li>
                <li>Payment processing services providers, merchants, banks and other persons that assists with the processing of your payment instructions.</li>
                <li>Insurers, brokers, other financial institutions or other organisations that assist with insurance and assurance underwriting, the providing of insurance and assurance policies and products, the assessment of insurance and assurance claims, and other related purposes.</li>
                <li>Law enforcement and fraud prevention agencies, and other persons tasked with the prevention and prosecution of crime.</li>
                <li>Regulatory authorities, industry ombudsmen, government departments, and local and international tax authorities.</li>
                <li>Credit bureaux.</li>
                <li>Trustees, executors or curators appointed by a court of law.</li>
                <li>Cheque verification service providers.</li>
                <li>Our service providers, agents and sub-contractors such as couriers and other persons we use to offer and provide products and services to you.</li>
                <li>Courts of law or tribunals.</li>
                <li>Our joint venture partners, e.g. multi-disciplinary team</li>
                <li>Digital data transformation or aggregation services which you have authorised to collect activity, health or other digital data streams and share with us.</li>
              </ul>
            </li>
            <li><strong>Processing Information about Persons Related to a Juristic Person</strong><br>
              <p>If you are a juristic person, such as a company or close corporation, we may collect and use personal information relating to the juristic person’s directors, officers, employees, beneficial owners, partners, shareholders, members, authorised signatories, representatives, agents, payers, payees, customers, guarantors, spouses of guarantors, sureties, spouses of sureties, other security providers and other persons related to the juristic person.  These are related persons.</p>
              <p>If you provide the personal information of a related person to us, you warrant that the related person is aware that you are sharing their personal information with us, and that the related person has consented thereto.</p>
              <p>We will process the personal information of related persons as stated in this Privacy Policy, thus references to “you” or “your” in this Privacy Policy will include related persons with the necessary amendments.</p>
            </li>
            <li><strong>Direct Electronic Marketing</strong><br>
              <p>If permission is given, <strong>iCare Nursing Services</strong> may use your personal or other information to tell you about products, services and special offers from <strong>iCare Nursing Services</strong> or other companies.</p>
              <p>We will use your personal information to market financial, insurance, investments and other related products and services to you.</p>
              <ul>
                <li>We will do this in person, by post, telephone, or electronic channels such as SMS, email and fax.</li>
                <li>If you are not our client, or in any other instances where the law requires, we will only market to you by electronic communications with your consent.</li>
                <li>In all cases, you can tell us to stop sending marketing communications to you at any time.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <strong>COOKIE POLICY</strong>
          <ol>
            <li>
              <strong>What happens if you disable your cookie functionality?</strong><br>
              <p>Clearing or disabling cookies may limit your website functionality, and your functionality once you’ve logged in.  You can limit the collection of your information by disabling cookies on your browser.  You may also be able to modify your browser settings to require your permission each time a site attempts to set a cookie.</p>
              <p>However, our website (and many other websites) rely on cookies to enable certain functionality.  If you choose to disable cookies, some of the services available on our website may not work properly.</p>
            </li>
            <li><strong>What are cookies?</strong><br>
              <p>A cookie is a small text file stored on your device by the website you are visiting.  It helps the website to remember information about your device and how you use the website.  We use this information to make your visit to our site as easy and useful as possible.</p>
            </li>
            <li><strong>Types of cookies we use?</strong><br>
                <p>There are two main types of cookies, i.e.</p>
                <ol>
                  <li><strong>Session cookies</strong>
                    <ul>
                      <li>When you close your browser, some cookies are deleted.  These are called session cookies.</li>
                    </ul>
                  </li>
                  <li><strong>Persistent cookies</strong>
                    <ul>
                      <li>Other cookies are stored on your device until they expire, or you choose to delete them.  They are called persistent cookies.  These cookies are sent back to us each time you visit our site.</li>
                    </ul>
                  </li>
                </ol>
            </li>
            <li><strong>What we use cookies for?</strong><br>
              <p>We use cookies for session management, user device identification and classification, traffic routing, and analytics.</p>
            </li>
            <li><strong>Is your personal information at risk?</strong><br>
              <p>No, we will never save any personal information, including login details or other personal information on your computer.</p>
            </li>
          </ol>
        </li>
        <li>
          <strong>LOCATION SERVICES</strong><br>
          <p>We use location services for specific products and services through our website and applications. The location services will only be switched on with the prior consent of the user.  The use of location services are set out in the purpose of collection of data.</p>
        </li>
        <li>
          <strong>CONFIDENTIALITY AND SECURITY</strong><br>
          <ol>
            <li><strong>Routine Precautions</strong><br>
              <p><strong>iCare Nursing Services</strong> has physical, technological and procedural security safeguards in place and will use its best endeavours to protect your personal information.</p>
              <p>Personal information refers to information that identifies or relates specifically to you, which for example include:</p>
              <ul>
                <li>Your name</li>
                <li>Age</li>
                <li>Gender</li>
                <li>Identity number</li>
                <li>Your assets and liabilities</li>
                <li>Your income</li>
                <li>Your employment details</li>
                <li>Payment records</li>
                <li>Your contact details</li>
                <li>Your marital status</li>
                <li>Family information</li>
                <li>Bank account information</li>
                <li>Medical or health information</li>
                <li>Your policy information</li>
              </ul><br>
              <p>Any information about your health behaviour, your health transactions and all related information will also be regarded as personal information.</p>
              <p>In short, any information that we know about you will be regarded as your personal information.</p>
            </li>
          </ol>
        </li>
        <li><strong>YOUR PERSONAL INFORMATION</strong><br>
          <ol>
            <li>
              <strong>Securing personal information</strong><br>
              <p><strong>iCare Nursing Services</strong> will take all reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.  We will store all the personal information in secured environments, for example on secured servers in a protected data centre.</p>
            </li>
            <li>
              <strong>How you can review and correct your personal information</strong><br>
              <p>You can request to review your personal information contained in <strong>iCare Nursing Services</strong> records at any time to correct or update the information.  If the purpose for which your personal information was requested initially does not exist anymore, you may request information held by <strong>iCare Nursing Services</strong> to be removed.  However, <strong>iCare Nursing Services</strong> can decline your request to delete the information from its records if other legislation requires the continued retention thereof or if it has been de-identified.</p>
            </li>
            <li>
              <strong>Employee Training on Cyber Security and Data Privacy</strong><br>
              <p>Employee Training on Cyber Security and Data Privacy forms part of ongoing compliance training. Cyber Security training is currently further required as a basic compliance training that all employees must complete.  As part of the POPIA management programme, there is a specific focus on training, awareness as well as communication that will cover data privacy, data security and more detailed cyber security training as mandatory compliance training to all staff.  The POPIA management programme is actively managed at <strong>iCare Nursing Services</strong>.</p>
            </li>
            <li>
              <strong>Centralised Cyber Security and Data Security Functions and Coordination</strong><br>
              <p>To deal with Cyber Security and Data Privacy, two separate centralised functions exist within <strong>iCare Nursing Services</strong>.  The IT Security environment includes managing cyber security as a capability and the Data Management environment deals with the aspects of data privacy and extended data security and privacy which is enabled through IT security.</p>
            </li>
            <li>
              <strong>Cookies</strong><br>
              <p>You agree that we shall be entitled to send "cookies" from this website to your computer.  We use the word "cookie" to refer to information that is sent from this website to your hard drive, where it is saved and contains information to personalise your experience on this website.  In this way, the next time you use this website we will know who you are and that you have visited this website before.</p>
            </li>
            <li>
              <strong>Third Parties</strong><br>
              <p>We do not exercise control over affiliate parties' privacy policies - Personal information and third parties. Because we are not responsible for any representations or information or warranties or content on any website of an affiliate party (including websites linked to this website or websites facilitated by us), we do not exercise control over affiliate parties' privacy policies, and you should refer to the privacy policy of any such affiliate party to see how such party protects your privacy.</p>
            </li>
            <li>
              <strong>Sharing your Information</strong><br>
              <p>Your privacy is important to us.  We will therefore not sell, rent or provide your personal information to unauthorised entities or other third parties, for their independent use, without your consent.</p>
              <p>We may disclose your personal information to the following third parties:</p>
              <ul>
                <li>Selected third parties to provide us with services, including companies that provide us with technical support and assistance in respect of the Website, companies that provide back-office services, companies that provide hosting services or, that track the Website’s activities and analytics, and companies engaged to market and distribute <strong>iCare Nursing Services</strong> products and/or services and conduct research on our behalf.</li>
                <li>Professional advisers, judicial, regulatory and law enforcement bodies; and</li>
                <li>A third party that acquires all or part of our assets or shares, or that succeeds us in carrying on all or a part of our business, whether by merger, acquisition, re-organisation or otherwise.</li>
                <li>In order for us to ensure that we meet your needs, we may collect and analyse your personal information and combine all the information that we have about you to compile a profile of you in order for us to personalise and tailor our services to meet your specific needs.</li>
                <li>Once we have collected and analysed your personal information, we may send you promotional material or details which we think may be of interest to you.  If any of this promotional information relates to products, promotions, news or services of an affiliate party, and if you indicate that you would like more details, we may inform the affiliate party to contact you directly.  We will, however, only inform an affiliate party to contact you directly if you have indicated that this is agreeable to you.</li>
              </ul><br>
              <p>If at any stage, after you have given us your consent, you no longer wish us to use or share your personal information with an affiliate party; you may at any stage withdraw your consent.  By choosing to withdraw your consent with affiliated third parties there may be an impact on our offering to you, and it will be explained to you on your request to withdraw your consent.</p>
            </li>
            <li>
              <strong>Cross Border Sharing</strong><br>
              <p>We will only transfer your personal information to third parties in another country in any one or more of the following circumstances:</p>
              <ul>
                <li>Where your personal information will be adequately protected under the other country’s laws or an agreement with the third-party recipient.</li>
                <li>Where the transfer is necessary to enter into, or perform, under a contract with you or a contract with a third party that is in your interest.</li>
                <li>Where you have consented to the transfer; and/or</li>
                <li>Where it is not reasonably practical to obtain your consent, and the transfer is in your interest.</li>
                <li>This transfer will happen within the requirements and safeguards of the law.</li>
                <li>Where possible, the party processing your personal information in the other country will agree to apply the same level of protection as available by law in your country, or if the other country’s laws provide better protection, the other country’s laws would be agreed to and applied.</li>
              </ul>
            </li>
          </ol>
        </li>
        <li><strong>CHANGES TO PRIVACY POLICY</strong><br>
          <p>Perusing amendments to the policy.  Our right to amend this Privacy Policy.  We reserve the right, in our sole discretion to amend (including without limitation, by the addition of new terms and conditions) this Privacy Policy from time to time.  You agree to review the Privacy Policy whenever you visit this website for any such amendments.  Save as expressly provided to the contrary in this Privacy Policy, the amended version of the Privacy Policy shall supersede and replace all previous versions thereof.</p>
          <p><strong>Which laws apply?</strong><br>
            This Privacy Policy will be governed by and construed and interpreted in accordance with the laws of South Africa.  To the extent that a court has jurisdiction over any dispute which may arise out of or in connection with this Privacy Policy, we both submit to the jurisdiction of the South African courts.
          </p>
        </li>
        <li><strong>GET IN TOUCH</strong><br>
          <p>Contact details of the Company’s Information Officer: <strong>iCare Nursing Services</strong> (to handle queries on behalf of <strong>iCare Nursing Services</strong>).</p>
          <table class="table" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td>Name of the Deputy Information Officer</td>
              <td>Viwe Tembani</td>
            </tr>
            <tr>
              <td>Physical Address</td>
              <td>47 Ocean Spirit Avenue, Milnerton, Cape Town</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td><a href="mailto:viwe@icarens.co.za" target="_blank">viwe@icarens.co.za</a></td>
            </tr>
            </tbody>
          </table>
          <p>General contact details of <strong>iCare Nursing Services</strong></p>
          <table class="table" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td>Physical Address</td>
              <td>47 Ocean Spirit Avenue, Milnerton, Cape Town</td>
            </tr>
            <tr>
              <td>Telephone Number</td>
              <td><a href="tel:0825675467" target="_blank">082 567 5467</a></td>
            </tr>
            <tr>
              <td>Internet Site URL</td>
              <td><a href="https://www.icarens.co.za" target="_blank">icarens.co.za</a></td>
            </tr>
            </tbody>
          </table>
        </li>
        <li><strong>COMPLAINTS</strong><br>
          <p>Should you believe that <strong>iCare Nursing Services</strong> has utilised your personal information contrary to Applicable Laws, you undertake to first attempt to resolve any concerns with <strong>iCare Nursing Services</strong> via the complaints process.</p>
          <p>If you are not satisfied with such process, you may have the right to lodge a complaint with the Information Regulator, using the contact details listed below:</p>
          <ul>
            <li>Tel: <a href="tel:0124064818" target="_blank">012 406 4818</a></li>
            <li>Fax: <a href="tel:0865003351">086 500 3351</a></li>
            <li>Email: <a href="mailto:inforeg@justice.gov.za" target="_blank">inforeg@justice.gov.za</a></li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</div>
