<div data-spy="scroll" data-target="#scrollspy-navbar" data-offset="0" class="">
  <section id="home" class="home-section">
    <div class="d-flex justify-content-center align-items-center justify-content-md-between h-100">
      <div class="container">
        <div class="intro-text">
          <div data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400">We want to help you.</div>
          <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="800">Let us help you get back to your best optimal level of health and wellbeing.</p>
          <a data-aos="flip-up" data-aos-duration="1000" data-aos-delay="1200" href="#contact" class="btn btn-outline-light btn-lg">Contact Us</a>
        </div>
      </div>
    </div>
    <a id="heart-animation" href="#about">
      <svg width="30px" height="26px" viewBox="0 0 30 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="heart-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="iCare-Home" transform="translate(-705.000000, -975.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <g id="Home">
              <path d="M734.966218,983.511741 C735.318705,979.740322 732.879955,976.264195 729.185983,975.272758 C725.492012,974.281321 721.612565,976.061685 719.991064,979.49251 C718.361233,976.198653 714.665135,974.436433 711.046278,975.227829 C707.298356,976.21459 704.77472,979.681307 705.015911,983.511741 C705.015911,990.522385 719.851544,1000.87728 720.037571,1001 L720.037571,1001 C720.130584,1000.87728 734.966218,990.737153 734.966218,983.511741 Z" id="More-Animation"></path>
            </g>
          </g>
        </g>
      </svg>
    </a>
  </section>
  <app-about></app-about>
  <app-services></app-services>
  <app-contact></app-contact>
</div>
