import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public services = [
    {
      serviceID: 'active-1',
      serviceIcon: '../../assets/services/home.png',
      serviceTitle: 'Home Nursing',
      serviceDesc: 'We provide around-the-clock nursing care for patients. We also offer short-term rehabilitative visits following surgery, illness or injury which may require physical therapy.',
      serviceList: [
        'Post Operative Care',
        'Frail Care',
        'Provision of Care',
        'Companionship'
      ]
    },
    {
      serviceID: 'active-2',
      serviceIcon: '../../assets/services/assessment.png',
      serviceTitle: 'Clinical Assessments',
      serviceDesc: 'Need a pesky physical for an insurance policy, no problem, we will come to you.',
      serviceList: [
        'Physical Assessment',
        'BP Monitoring',
        'Diabetes',
        'Cholesterol',
        'Weight',
        'Height',
        'BMI',
        'Urine analysis'
      ]
    },
    {
      serviceID: 'active-3',
      serviceIcon: '../../assets/services/disease.png',
      serviceTitle: 'Disease Risk',
      serviceDesc: 'Assessments can be arranged at home or at work',
      serviceList: [
        'Chronic Illness Care',
        'Education for Diabetes',
        'Cholesterol',
        'Cardiac care',
        'Obesity'
      ]
    },
    {
      serviceID: 'active-4',
      serviceIcon: '../../assets/services/medical-procedure.png',
      serviceTitle: 'Medical Procedures',
      serviceDesc: 'Your urologist can contact us and have your medication delivered to our premises. Antibiotics given daily in the comfort of your home or at our premises',
      serviceList: [
        'BCG Installation',
        'Daily Antibiotics',
        'Wound Care',
        'Pap Smears'
      ]
    },
    {
      serviceID: 'active-5',
      serviceIcon: '../../assets/services/respite-care.png',
      serviceTitle: 'Respite Care',
      serviceDesc: 'We offer your main caregiver a chance to recharge for a limited time. You can decide on the hours.  We will request authorization from your funder and if respite care is an exclusion, give you a good cash rate.',
      serviceList: []
    },
    {
      serviceID: 'active-6',
      serviceIcon: '../../assets/services/medical-aid.png',
      serviceTitle: 'Medical Reconciliation',
      serviceDesc: 'Are you battling to reconcile your medical claims against your medical aid statements or are there short payments on your medical claims that you don’t understand? Let us help you to clear this up and preserve your relationship with your funder.',
      serviceList: []
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
