<section id="services" class="bg-light services">
  <div class="container-fluid h-100">
    <div class="d-flex flex-md-row flex-column h-100 justify-content-center align-items-center">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="horizontal">
        <li data-aos="fade-right" data-aos-deuration="1000" data-aos-delay="300" ngbNavItem *ngFor="let service of services">
          <a ngbNavLink>{{service.serviceTitle}}</a>
          <ng-template ngbNavContent>
            <div class="{{service.serviceID}}">
              <img data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500" src="{{service.serviceIcon}}" width="100px"/>
              <h1 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="600">{{service.serviceTitle}}</h1>
              <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay="700">{{service.serviceDesc}}</p>
              <ul data-aos="fade-right" data-aos-duration="1000" data-aos-delay="800">
                <li *ngFor="let item of service.serviceList">
                  {{item}}
                </li>
              </ul>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</section>

