<nav id="scrollspy-navbar" class="navbar navbar-expand-md navbar-dark fixed-top">
  <a class="navbar-brand" data-aos="zoom-in" data-aos-duration="1000" href="#">
    <img src="../../assets/iCare_logo_white.svg" alt="iCare Nursing Services"/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarContent">
    <ul class="navbar-nav ml-auto">
      <li data-aos="zoom-in" data-aos-delay="300" class="nav-item">
        <a class="nav-link" href="#home">HOME</a>
      </li>
      <li data-aos="zoom-in" data-aos-delay="400" class="nav-item">
        <a class="nav-link" href="#about">ABOUT</a>
      </li>
      <li data-aos="zoom-in" data-aos-delay="500" class="nav-item">
        <a class="nav-link" href="#services">SERVICES</a>
      </li>
      <li data-aos="zoom-in" data-aos-delay="600" class="nav-item">
        <a class="nav-link" href="#contact">CONTACT</a>
      </li>
    </ul>
  </div>
</nav>
